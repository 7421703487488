const submenu = document.querySelector(".sub-menu");
const firstTag = document.querySelectorAll(".menu-item-object-post_tag")[0];

const tagIndex = Array.prototype.indexOf.call(submenu.children, firstTag);
const submenuItems = submenu.childNodes;

const submenuWrapper = document.createElement("div");
submenuWrapper.classList.add("submenu-wrapper");
submenu.insertBefore(submenuWrapper, submenu.children[0]);

submenuItems.forEach((el) => {
  if (el.nodeName === "LI") {
    submenuWrapper.appendChild(el);
  }
});

const cats = document.querySelectorAll(".menu-item-object-kategoria");
const tags = document.querySelectorAll(".menu-item-object-post_tag");

const catsText = document.createElement("p");
const tagsText = document.createElement("p");
catsText.innerHTML = "Produkty";
tagsText.innerHTML = "Rodzaj zabawy";

const catsWrapper = document.createElement("div");
catsWrapper.classList.add("cats-wrapper");
catsWrapper.insertBefore(catsText, catsWrapper.children[0]);
submenuWrapper.insertBefore(catsWrapper, submenuWrapper.children[0]);

const tagsWrapper = document.createElement("div");
tagsWrapper.classList.add("tags-wrapper");
tagsWrapper.insertBefore(tagsText, tagsWrapper.children[0]);
submenuWrapper.insertBefore(tagsWrapper, submenuWrapper.children[tagIndex + 1]);

cats.forEach((cat) => {
  if (cat.nodeName === "LI") {
    catsWrapper.appendChild(cat);
  }
});

tags.forEach((tag) => {
  if (tag.nodeName === "LI") {
    tagsWrapper.appendChild(tag);
  }
});
