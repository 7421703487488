const showCats = document.querySelector("#show-cats");
if (showCats) {
  showCats.addEventListener("click", () => {
    showCats.classList.toggle("active");
    if (showCats.classList.contains("active")) {
      showCats.innerHTML = "Ukryj kategorie";
    } else {
      showCats.innerHTML = "Pokaż kategorie";
    }
    const sidebarBox = document.querySelector(".products__sidebarBox");
    if (sidebarBox) {
      sidebarBox.classList.toggle("show");
    }
  });
}
