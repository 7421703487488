const infoIcons = document.querySelectorAll(".parameters-icon");
if (infoIcons) {
  infoIcons.forEach((icon) => {
    icon.addEventListener("click", () => {
      if (icon.classList.contains("active-icon")) {
        infoIcons.forEach((el) => {
          el.classList.remove("active-icon");
        });
        icon.classList.remove("active-icon");
      } else {
        infoIcons.forEach((el) => {
          el.classList.remove("active-icon");
        });
        icon.classList.add("active-icon");
      }
      icon.parentNode.childNodes.forEach((child) => {
        if (child.nodeName === "P") {
          if (child.classList.contains("info")) {
            const allInfo = document.querySelectorAll(".info");
            if (child.classList.contains("show-info")) {
              allInfo.forEach((info) => {
                info.classList.remove("show-info");
              });
              child.classList.remove("show-info");
            } else {
              allInfo.forEach((info) => {
                info.classList.remove("show-info");
              });
              child.classList.add("show-info");
            }
          }
        }
      });
    });
  });
}
