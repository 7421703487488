const openPopup = document.querySelector("#open-popup");
const html = document.querySelector("html");
if (openPopup) {
  openPopup.addEventListener("click", () => {
    const popup = document.querySelector(".popup-box");
    const closePopup = document.querySelector("#close-popup");
    popup.classList.add("popup-box--active");
    html.style.overflowY = "hidden";
    closePopup.addEventListener("click", () => {
      popup.classList.remove("popup-box--active");
      html.style.overflowY = "scroll";
    });
  });
}
